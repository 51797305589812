
@import '~react-h5-video/lib/react-html5-video.css';
@import '~video-react/dist/video-react.css';

@import './assets/css/style.css';
@import './assets/css/font-awesome.min.css';
@import './assets/css/font-circle-video.css';




.f-copy{
	padding-top: 25px;
}

footer .f-icon {
    padding-top: 20px;
}

body.dark .navbar-container, body.dark .navbar-container2 {
    border-width: 0px !important;
}

.nav-btn {
    margin-top: 10px;
    background-color: #ea2c5a;
    color: #fff;
    padding: 10px 25px;
    border: solid 1px #ea2c5a;
    height: 48px;
    border-radius: 24px;
    font-weight: 500;
    
}

.btn-copy{
	background-color: #4e8cff;
    color: #fff;
    border: solid 1px #4e8cff;
    border-radius: 5px;
    padding: 2px 15px;
}

.btn-copy:hover{
	background-color: #337ab7;
}

.navbar-container {
    position: fixed;
    background-color : #fff;
}

.content-wrapper{
	 padding-top: 95px;
	 padding-bottom: 40px;
}

.hidePlayer{
	display: none !important;
}

.showPlayer{
	display: block !important;
}

ul.menu {
    margin-top: 20px;
}

.navbar-brand span {
    display: block;
}

.brand-name{
	font-size: 24px;
	 color: #777;
	 margin-top:15px;
	 text-shadow: 2px 0 #333;
	 font-weight: '900';
	
}

.upload-page .u-terms {
    padding: 0px 0 5px 0px;
}

.padding-def {
    padding: 30px 0 70px 0;
}


button:focus {outline:0 !important; }


video::-webkit-media-controls-overlay-enclosure, 
video::-webkit-media-controls-play-button, 
video::-webkit-media-controls-mute-button, 
video::-webkit-media-controls-pausebutton,
video::-webkit-media-controls-timeline-container,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display
{
        display: none !important;
}


.upload-page .u-area {
    padding-top: 140px;
    padding-bottom: 100px;
}

.upload-page .u-area .u-text1 {
    display: block;
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 20px;
    color: #2e2e2e;
}

.upload-page .u-area .u-btn {
    margin-top: 40px;
 }
 

.u-loaderarea{
    padding-top: 220px;
    padding-bottom: 300px;
}

.isa_error {
    color: #D8000C;
    background-color: #FFD2D2;
    padding: 10px;
    text-align: center;
    border-radius: 3px;
}


.upload-page .u-area .stop-btn {
    margin-top: 60px;
    background-color: red;
    color: #fff;
    padding: 15px 65px;
    border: solid 1px #ea2c5a;
    height: 48px;
    border-radius: 24px;
    font-weight: 500;
}